import { graphql, useStaticQuery } from "gatsby";

import { OxBackToTop } from "src/components/OxBackToTop";
import { OxComponentContainer } from "src/components/OxComponentContainer";
import React from "react";
import { WhoWeAre } from "src/components/Marketing/WhoWeAre";
import { BookAnAppointment } from "src/components/Marketing/BookAnAppointment";
import { Hero } from "src/components/Marketing/Hero";
import { AdvisoryPanel } from "src/components/Marketing/AdvisoryPanel";
import { OxPageHelmet } from "src/components/OxPageHelmet";

const Marketing3Page = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      consultationTabletImage: file(
        relativePath: { eq: "homepage/BookingBanner-Tablet.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      consultationMobileImage: file(
        relativePath: { eq: "homepage/BookingBanner-Mobile.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      drSabikaMobileImage: file(
        relativePath: { eq: "doctors/Doctor-DrSabika-Mobile-1100x800.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      drSabikaDesktopImage: file(
        relativePath: { eq: "doctors/Doctor-DrSabika-Desktop-960x870.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      drRajQaDesktop: file(
        relativePath: { eq: "doctors/Doctor-BIO-DrRaj-QA-Desktop.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      drRajQaMobile: file(
        relativePath: { eq: "doctors/Doctor-BIO-DrRaj-QA-Mobile.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);

  const storyImages = [
    {
      ...data.consultationMobileImage.childImageSharp.gatsbyImageData,
      media: `(max-width: 768px)`,
    },
    {
      ...data.consultationTabletImage.childImageSharp.gatsbyImageData,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <>
      <OxPageHelmet
        title="3 | Ouronyx | Luxury Facial Aesthetics"
        description="Ouronyx is the next generation of facial aesthetics. We’re the first global aesthetics destination, helping people to self-optimise with highly personalised, non-surgical treatments, delivered by the world’s best-in-class Doctors."
        keywords="Aesthetic doctor, non surgical, bespoke treatments, luxurious, St James, London, inspiring, leaders"
      />
      <OxComponentContainer marginBottom={[40, 40, 40]}>
        <Hero />
      </OxComponentContainer>

      <OxComponentContainer marginBottom={[40, 40, 40]}>
        <WhoWeAre fluidImages={storyImages} />
      </OxComponentContainer>

      <OxComponentContainer marginBottom={[40, 40, 40]}>
        <BookAnAppointment />
      </OxComponentContainer>

      <OxComponentContainer marginBottom={[40, 40, 40]}>
        <AdvisoryPanel primarySliderKey="dr-iman" />
      </OxComponentContainer>

      <OxBackToTop />
    </>
  );
};

export default Marketing3Page;
